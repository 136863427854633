import { Button } from "@/components/base";
import { Textarea } from "@/components/third-party/shadcn/textarea";
import { qooMediaService } from "@/service/qoo/qoo-media.service";
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { HeygenTemplateDropdown } from "@/components/dropdown/HeygenTemplateDropdown";
import { Icons } from "@/components/icons";
import { toast } from "@/components/third-party/shadcn/use-toast";
import { AlertDialog } from "../alert/AlertDialog";

export const GenerateScriptModal = ({
  qooId,
  heygenThirdpartyId,
  isOpen,
  onClose,
  onGenerateVideo,
}: {
  qooId: number;
  heygenThirdpartyId: number;
  isOpen: boolean;
  onGenerateVideo: () => void;
  onClose: any;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [prompt, setPrompt] = useState(DEFAULT_PROMPT);
  const [initialScript, setInitialScript] = useState("");
  const [script, setScript] = useState("");
  const [templateId, setTemplateId] = useState(null);
  const [videoGenerating, setVideoGenerating] = useState(false);
  const [generateVideoOpen, setGenerateVideoOpen] = useState(false);
  const [closeOnConfirm, setCloseOnConfirm] = useState(false);

  const heygenWindow = useRef(null);

  const init = async () => {
    const script = await qooMediaService.getHeygenScript(heygenThirdpartyId);
    setInitialScript(script);
    setScript(script);
  };

  const handleGenerateScript = async () => {
    setIsLoading(true);
    const scriptResp = await qooMediaService.generateVideoScript(qooId, prompt);
    setScript(scriptResp);
    setIsLoading(false);

    openHeygenEditor(scriptResp);

    toast({
      title: "Copied script to clipboard",
      description: "You can now paste it into Heygen",
    });
  };

  const openHeygenEditor = (currentScript?: string) => {
    navigator.clipboard.writeText(currentScript || script);

    // Open new Heygen window to test script
    const heygenUrl = `https://app.heygen.com/template-v3/edit/4566c2852cc0488b9bf2176e79344b95?tab=script`;
    const features =
      "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=800,height=600";

    if (!heygenWindow.current) {
      const newWindow = window.open(heygenUrl, "_blank", features);
      if (newWindow) {
        heygenWindow.current = newWindow;

        var timer = setInterval(function () {
          if (newWindow.closed) {
            clearInterval(timer);
            heygenWindow.current = null;
          }
        }, 1000);
      }
    }

    heygenWindow.current.focus();
    // heygenwindow current navigate to heygenUrl
    heygenWindow.current.location.href = heygenUrl;
  };

  const handleGenerateVideo = async () => {
    setVideoGenerating(true);
    setIsLoading(true);
    await qooMediaService
      .regenerateHeygenVideo(qooId, templateId, script)
      .then(() => {
        setInitialScript(script);
        toast({
          title: "Video Generated",
          description:
            "Video is being generated. It is safe to close this drawer.",
        });

        onGenerateVideo();
        handleClose();
      })
      .catch(() => {
        toast({
          title: "Error",
          description: "An error occurred while generating video",
        });
      })
      .finally(() => {
        setVideoGenerating(false);
        setIsLoading(false);
      });
  };

  const handleSelectTemplate = (template) => {
    setTemplateId(template.template_id);
  };

  const handleClose = () => {
    if (heygenWindow.current) {
      heygenWindow.current.close();
      heygenWindow.current = null;
    }

    setPrompt(DEFAULT_PROMPT);
    setScript("");
    setTemplateId(null);
    setGenerateVideoOpen(false);
    setCloseOnConfirm(false);
    onClose();
  };

  const attemptClose = () => {
    if (script?.length || isLoading || generateVideoOpen) {
      setCloseOnConfirm(true);
    } else {
      handleClose();
    }
  };

  useEffect(() => {
    if (heygenThirdpartyId && isOpen) {
      init();
    }
  }, [heygenThirdpartyId, isOpen]);

  // Automatically generate script on init
  useEffect(() => {
    if (qooId && isOpen) {
      handleGenerateScript();
    }
  }, [qooId, isOpen]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={attemptClose} size="lg" isCentered>
        <ModalOverlay />
        <ModalContent py="1" px="5" minWidth="900px" minH="600px">
          <div className="mt-4 flex flex-row justify-between">
            <div>
              <div className="flex justify-between items-center font-semibold">
                Test Generate Script
              </div>
              <div className="text-sm text-[gray] align-center">
                {(script?.split(" ")?.length === 1
                  ? 0
                  : script?.split(" ")?.length) ?? 0}{" "}
                words · {script?.length ?? 0} characters
              </div>
            </div>
            <div onClick={attemptClose}>
              <Icons.Error />
            </div>
          </div>
          <div className="flex flex-row gap-2">
            <div className=" w-[600px] border-r border-gray-300 pr-2">
              <Textarea
                disabled={!script?.length || isLoading}
                rows={18}
                className="!text-[black] p-4 mt-2"
                value={script}
                onChange={(e) => setScript(e.target.value)}
                placeholder="Begin by clicking 'Generate Script'"
              />

              <HeygenTemplateDropdown onSelect={handleSelectTemplate} />

              <Button
                onClick={() => setGenerateVideoOpen(true)}
                disabled={isLoading || !script?.length}
                loading={videoGenerating}
                className="mt-2 mr-2"
              >
                Generate Video
              </Button>
              <Button
                onClick={() => openHeygenEditor(script)}
                variant="outline"
                disabled={isLoading || !script?.length}
                loading={videoGenerating}
                className="mt-2 mr-2"
              >
                Heygen Editor
              </Button>
            </div>

            <div className="w-[450px] pt-[6px]">
              <Textarea
                rows={16}
                title="Prompt"
                className="!text-[black] p-4"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
              />
              <div className="text-sm text-[gray] my-2">
                Modify the prompt to generate a script
              </div>
              <div className="flex justify-end gap-2 mt-4">
                <Button
                  onClick={handleGenerateScript}
                  disabled={isLoading}
                  loading={isLoading}
                >
                  <Icons.Wand stroke="white" />
                  Generate Script
                </Button>
              </div>
            </div>
          </div>
        </ModalContent>
      </Modal>
      <AlertDialog
        header="Confirm Generate"
        body="Are you sure you want to generate a video? Existing video will be overwritten."
        isOpen={generateVideoOpen}
        onClose={() => setGenerateVideoOpen(false)}
        onConfirm={handleGenerateVideo}
      />

      <AlertDialog
        header="Please confirm"
        variant="destructive"
        body="Are you sure you want to leave this page? Your changes will be lost."
        isOpen={closeOnConfirm}
        onClose={() => setCloseOnConfirm(false)}
        onConfirm={handleClose}
      />
    </>
  );
};

const DEFAULT_PROMPT = `
Objective:
Generate a concise transcript for a micro-learning module that fits within 60 seconds of spoken time (with a 10-second margin on either side for a total of no more than 70 seconds). Ensure the transcript is clear, engaging, and adheres strictly to the guidelines below.

Key Requirements:
1. Zero Tolerance for Filler or Unnecessary Phrases:
	•	Prohibited phrases include but are not limited to:
	•	“as one expert puts it”
	•	“as one recruiter puts it”
	•	“as a recruiter said”
	•	“for example”
	•	“you know”
	•	“imagine this”
	•	“as the saying goes”
	•	“consider this”
	•	Any similar transitions or introductory phrases.
	•	Under no circumstances should any form of filler phrase or commentary be included.
	•	The output must be immediately rejected if any of these filler or commentary phrases are detected.

2. Enforce Seamless Integration of Content:
	•	All quotes, key points, and transcript text must be used exactly as provided—word for word—without alterations, omissions, or paraphrasing.
	•	Quotes and anecdotes should be directly woven into the transcript naturally, with no filler or introductory phrases whatsoever. Do not include Titles, Chapters, or Headings in your response.

3. Context and Clarity:
	•	Always ensure the full context is provided when incorporating quotes, key points, and anecdotes.
	•	Do not omit any details that clarify the meaning or relevance of the quote or anecdote.

4. Triple Verification of Output:
The output must undergo the following three distinct checks to ensure compliance:
Check 1: No filler or unnecessary phrases.
	•	Manually verify that none of the following phrases are present in the output:
	•	“as one expert puts it”
	•	“as one recruiter puts it”
	•	“as a recruiter said”
	•	“for example”
	•	“you know”
	•	“imagine this”
	•	“as the saying goes”
	•	“consider this”
	•	Any other similar filler phrases.
	•	The output must be immediately rejected if any such phrase is found.

Check 2: Quotes are word-for-word and seamlessly integrated.
	•	Manually confirm that all quotes and key points are included word-for-word exactly as provided.
	•	Ensure that the quotes are woven seamlessly into the text with no additional commentary, filler, or introductory phrases.

Check 3: Natural flow and adherence to time.
	•	Check the final transcript to ensure it has a natural and engaging flow, without any awkward transitions or repetition.
	•	Confirm that the transcript fits within the 60-70 second time limit for spoken delivery.

5. Double Verification Process:
	•	After the transcript is generated, it must go through two rounds of review to ensure that:
	•	Round 1: No prohibited filler phrases or commentary are present.
	•	Round 2: Quotes are used word-for-word and integrated seamlessly.
If either round fails, the transcript must be corrected and checked again.

6. Triple Verification Safeguard:
	•	Final Review: Before the transcript is finalized, it undergoes a final, comprehensive review to ensure:
	•	No filler phrases or unnecessary commentary are present.
	•	All quotes and key points have been used word-for-word.
	•	The transcript flows naturally, is concise, and adheres to the time limit.

If any step fails this check, the output is immediately rejected, and the process starts again.

Breakdown of Double-Check and Triple-Check Processes:
	1.	Double Verification Process: This ensures that the transcript passes two separate reviews focusing on filler phrases and accurate quote integration.
	2.	Triple Verification Safeguard: A final check is conducted after the double verification to ensure nothing has been missed. The transcript is reviewed holistically for natural flow, accuracy, and adherence to time.`;
