import React from "react";
import { Button } from "@/components/base";
import {
  AlertDialog as ChakraAlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Stack,
} from "@chakra-ui/react";

interface AlertDialogProps {
  isOpen: boolean;
  header: string;
  body: string;
  onClose: () => void;
  onConfirm: () => void;
  loading?: boolean;
  variant?: "destructive" | "default"; 
}

export const AlertDialog = (props: AlertDialogProps) => {
  const { isOpen, onClose, onConfirm, header, body, loading, variant } = props;

  const cancelRef = React.useRef();
  return (
    <ChakraAlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {header}
          </AlertDialogHeader>
          <AlertDialogBody>{body}</AlertDialogBody>
          <AlertDialogFooter>
            <Stack direction="row" spacing={1}>
              <Button disabled={loading} variant="outline" ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button loading={loading} variant={variant} onClick={onConfirm}>
                Confirm
              </Button>
            </Stack>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </ChakraAlertDialog>
  );
};
