export const convertIsoTime = (isoString: string | Date) => {
    const date = new Date(isoString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
  
    return `${day}/${month}/${year}`;
  };
  
  export const getDayPeriod = () => {
    const currentHour = new Date().getHours();
    if (currentHour >= 0 && currentHour < 12) {
      return "morning";
    } else if (currentHour >= 12 && currentHour < 17) {
      return "afternoon";
    } else {
      return "evening";
    }
  };
  
  export function getYearDifference(
    startYear?: string | number,
    endYear?: string | number
  ): number {
    const currentYear = new Date().getFullYear();
    const start = startYear ? parseInt(startYear.toString(), 10) : currentYear;
    const end = endYear ? parseInt(endYear.toString(), 10) : currentYear;
  
    if (isNaN(start) || isNaN(end)) {
      throw new Error(
        "Invalid input: startYear and endYear must be numbers or numeric strings."
      );
    }
  
    return Math.abs(end - start);
  }
  